.highlight {
  margin: 1rem 0;
  padding: 0;
  border: 0;
  white-space: normal;
  background: transparent;

  pre {
    line-height: 0;
  }
}

pre {
  margin: 0;
  padding: 1rem;
  font-size: 1rem;
  white-space: normal;
  border-radius: 0;
}

pre code {
  font-family: "Fira Code", monospace !important;
  font-size: 1rem;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  white-space: pre;
  background: transparent;
  display: inline-block;
  overflow-x: auto;
  line-height: 150%;
}

li code,
p > code {
  font-family: "Fira Code", monospace;
  font-size: 1rem;
  background: rgba(0, 0, 0, 0.08);
  padding: 2px 10px;
  font-weight: 400;
  border-radius: 10px;
  display: inline-block;
}

p > code > * {
  font-family: "Fira Code", monospace;
  font-size: 1rem;
}
