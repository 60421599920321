#home-page {
  $font-family-page: Arial, sans-serif;
  $font-color-read-more: cadetblue;
  $background-color-page: #fff;

  @media screen and (min-width: 1600px) {
    display: flex;
    justify-content: space-between;
    max-width: 1280px;
  }

  ul.latest-posts-list {
    list-style: none;
    margin: 0;
    padding: 0 0 6rem;

    @media screen and (min-width: 1600px) {
      margin: 0 auto;
    }

    li.latest-post {
      list-style: none;
      border-top: 1px dashed #000;
      margin: 0;
      padding: 2rem 0;

      &:first-child {
        border-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }

      h2 {
        margin: 0 0 2rem;
        text-decoration: none;
        font-size: 1.2rem;
        font-family: $font-family-page;
      }

      h3 {
        font-size: 1rem;
      }

      .created-at {
        margin: 0.83rem 0 0;
      }

      .summary {
        margin: 0.83rem 0 0;
        text-align: justify;
      }

      .read-more {
        margin: 0.83rem 0 0;

        a {
          color: $font-color-read-more;
        }
      }
    }
  }

  .sections-list {
    margin: 0;
    padding: 0;
    list-style: none;

    .sections-list-entry {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
}
