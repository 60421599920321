// body
body {
  min-height: 100vh;
  background-color: #dcd9d4;
}

// padder
.padder {
  padding: 1rem 1.25rem;
}

// limiter

.size-limiter {
  box-sizing: border-box;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}

// main content

.main-content {
  box-sizing: border-box;
  padding: 0 1.25rem;
}

// images

img {
  max-width: 100%;
  height: auto;
}

// content

#content {
  background: rgba($color: #fff, $alpha: 1);
}

// hyperlinks

a {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-decoration: underline;

  &:hover {
    color: inherit;
  }
}

// image preview

.image-preview-container {
  border: 1px solid #000;
  background: #000;
  margin: 0 0 3rem;

  @media print {
    display: none;
  }

  .image-preview {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
}

// headings

#content h1 {
  border-bottom: 1px solid #000;
  margin: 0;
  padding: 1.5rem 0;
}
