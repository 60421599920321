#google-search-results {
  background-color: #fff;

  #___gcse_0 {
    width: 100%;

    .gsc-control-cse {
      margin: 0;
      padding: 0 !important;
    }
  }

  .gsc-search-box.gsc-search-box-tools {
    display: none;
  }

  .search-results {
    margin: 0 20px;
    background: #fff;
  }

  .gsc-resultsbox-visible {
    padding: 40px 0;
  }

  table {
    margin: 0;
    padding: 0;

    th,
    td {
      border: 0;
    }
  }

  .gsc-above-wrapper-area {
    border: 0;
  }

  .gsc-above-wrapper-area-container {
    border: 0;
  }

  .gsc-results-wrapper-visible {
    padding: 40px 0 0;
  }

  * {
    font-size: 1rem;
  }

  .gsc-result-info-container {
    padding: 0;
  }

  a.gs-title {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.2rem;
    text-decoration: none;

    b {
      font-style: normal;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.2rem;
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
