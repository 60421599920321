.article-cols {
  $min-width-large: 1400px;

  @media screen and (min-width: $min-width-large) {
    display: flex;
  }

  .article-content {
    padding: 0 0 4rem;

    @media screen and (min-width: $min-width-large) {
      width: 70%;

      &.full {
        width: 100%;
      }
    }
  }

  .article-extras {
    padding: 0 0 4rem;

    h2 {
      padding-bottom: 1.45rem;
      border-bottom: 1px solid #000;
    }

    @media screen and (min-width: $min-width-large) {
      width: 25%;
      margin-left: 5%;
    }
  }
}
