blockquote {
  $font-family-primary: Georgia, serif;
  $font-color-primary: #555;
  $background-color-primary: rgba(#ccc, 0.25);
  $font-family-cite: $font-family-primary;
  $font-color-cite: #999;

  margin: 2rem 0;
  padding: 1.25rem;
  background-color: $background-color-primary;
  font-size: 1rem;
  font-family: $font-family-primary;
  line-height: 1.5;
  color: $font-color-primary;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  p:first-of-type {
    margin-top: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  &:before {
    content: "\201C";
    color: #888;
    font-size: 1.5rem;
    line-height: 0.1;
  }

  cite {
    display: block;
    font-size: 1rem;
    font-style: italic;
    color: $font-color-cite;
  }

  p:first-child {
    display: inline;
  }
}
