#not-found-page {
  $font-color-input: #000000;
  $background-color-search: rgba(217, 217, 217, 0.5);
  $background-color-button: #2980ba;
  $min-width-large-screen: 1280px;

  @media screen and (min-width: 1280px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .not-found-image {
    font-size: 0;

    img {
      display: block;
      border: 1px solid #000;
      margin: 0 auto;
      max-width: 100%;
      max-height: 800px;

      @media screen and (min-width: $min-width-large-screen) {
      }
    }

    @media screen and (min-width: $min-width-large-screen) {
      width: 45%;
      max-width: 600px;
    }
  }

  .details {
    padding: 0 0 4rem;

    @media screen and (min-width: $min-width-large-screen) {
      width: 45%;
    }
  }

  .search-form {
    margin: 60px auto 0;
    max-width: 450px;
    box-sizing: border-box;
    position: relative;
    height: 2rem;
    background: $background-color-search;
    border-radius: 30px;
    display: flex;
  }

  .search-input {
    border: 0;
    padding: 0;
    outline: none;
    flex-grow: 1;
    margin: 7px 17px;
    background: transparent;
    width: 100%;

    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1rem;
    color: $font-color-input;
  }

  .search-button {
    margin: 0 0 0 auto;
    padding: 0;
    border: 0;
    width: 4rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: $background-color-button;
    border-radius: 30px;
    cursor: pointer;
  }
}
