ol,
ul {
  margin: 0;
  padding: 0;
}

ol li,
ul li {
  padding: 0;
  margin: 0 0 0.8rem;
  list-style: none;
}

ol li,
ul li {
  list-style-position: inside;
  list-style-type: disc;
}

ol {
  margin-bottom: 0.8rem;
}

ol ol,
ol ul,
ul ul,
ul ol {
  padding-left: 0.8rem;
  margin-bottom: 0.8rem;
}

ol li,
ul li {
  padding-top: 1px;
  padding-bottom: 1px;
}

dl {
  margin: 0.8rem 0;
}

dl > dt {
  font-style: italic;
  font-weight: bold;
  margin: 1.2rem 0 0.2rem 0;
}

dl dd {
  margin: 0 0 0.8rem;
  padding: 0;
}

ul {
  margin-bottom: 0.8rem;
}

ul > li {
  list-style: disc;
  margin: 0.8rem 0 0 1.32rem;
}

ul > li:last-child {
  margin-bottom: 0;
}

ol {
  margin: 0.8rem 0 0 0.8rem;
  list-style-type: decimal;
}

ol > li {
  list-style-type: decimal;
}

ol > li:last-child {
  margin-bottom: 0;
}

ul > li > ul > li {
  list-style: square;
  margin: 0.8rem 0 0;
}

ol {
  li {
    p {
      display: inline;
    }
  }
}
