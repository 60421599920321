#list-page {
  padding: 0 0 6rem;

  h2 {
    margin: 0 0 2rem;
    text-decoration: none;
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1rem;
  }

  .description {
    margin: 2rem 0;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .pages-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
