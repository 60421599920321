h1.main {
  border-bottom: 1px solid #000;
  margin: 0 0 2rem;
  padding: 1rem 0;

  img {
    height: 1rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1 {
  margin: 1.5rem 0;
  padding: auto;
  font-style: normal;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: #000000;
}

h2 {
  font-size: 1.25rem;
  margin: 1.45rem 0;
}

h3 {
  font-size: 1.2rem;
  margin: 1.4rem 0;
}

h4 {
  font-size: 1.15rem;
  margin: 1.35rem 0;
}

h5 {
  font-size: 1.1rem;
  margin: 1.3rem 0;
}

h6 {
  font-size: 1.05rem;
  margin: 1.25rem 0;
}

p {
  margin: 1rem 0 0;
  font-size: 1rem;
  line-height: 1.2rem;
}
