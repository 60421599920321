header {
  $font-color-header: #000000;
  $background-color-header: #fff;
  $min-width-small: 811px;
  $min-width-large: 1280px;

  background: $background-color-header;
  border-bottom: 1px solid #000;

  @media screen and (min-width: $min-width-large) {
    padding: 1rem 1.25rem;
  }

  @media print {
    display: none;
  }

  .header {
    @media screen and (min-width: $min-width-small) {
      display: flex;
      align-items: center;
    }
  }

  .logo-container {
    text-align: center;
    padding: 1rem 1.25rem;

    @media screen and (min-width: $min-width-small) {
      text-align: left;
    }

    @media screen and (min-width: $min-width-large) {
      padding: 1rem 0;
    }
  }

  .search-container {
    display: flex;
    align-items: center;
    padding: 1rem 1.25rem;
    background: #f4f4f4;

    @media screen and (min-width: $min-width-small) {
      margin: 0 0 0 auto;
      background-color: transparent;
    }

    @media screen and (min-width: $min-width-large) {
      padding: 1rem 0;
    }
  }

  .search-form {
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    height: 2rem;
    width: 100%;
    max-width: 18rem;
    border-radius: 1.2rem;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;

    label {
      margin: 0 0 0 0.68rem;
      font-size: 0.8rem;
      color: #555;
    }
  }

  .search-input {
    border: 0;
    padding: 0;
    outline: none;
    flex-grow: 1;
    margin: 0.28rem 0.68rem;
    background: transparent;
    width: 100%;
    text-align: center;

    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1rem;
    color: $font-color-header;
  }

  .search-button {
    margin: 0 0 0 auto;
    padding: 0;
    border: 0;
    width: 4rem;
    min-width: 4rem;
    max-width: 4rem;
    height: 2rem;
    background: #2980ba;
    border-radius: 1.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
