table {
  margin: 2rem 0;
  border: 1px solid #ddd;
  border-collapse: collapse;
  width: 100%;
  font-size: 1rem;

  th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
    text-align: left;
    padding: 0 1rem;
    border-bottom: 2px solid #ddd;
  }

  th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
    text-align: left;
    padding: 1rem;
    border-bottom: 2px solid #ddd;
    white-space: pre;
  }

  td {
    border-bottom: 1px solid #ddd;
    padding: 1rem;
    vertical-align: top;
  }

  tr:hover {
    background-color: #f5f5f5;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}
