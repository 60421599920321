.paginator {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  line-height: 1rem;

  li {
    list-style: none;
    margin: 0 0 1rem;
    padding: 0;
    font-size: 0.8rem;
    line-height: 1rem;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .published-at {
      color: #000;
      font-weight: bold;
      font-style: italic;
    }
  }
}
