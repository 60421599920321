#single-page {
  .youtube-video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    margin: 1.45rem 0 0;

    @media print {
      display: none;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  h1 {
    margin: 0 0 1.45rem;

    &.clean {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }

  .article-extras {
    @media print {
      display: none;
    }

    .paginator {
      margin: 0 0 3rem;
    }
  }

  .pager {
    @media print {
      display: none;
    }

    margin: 5rem 0 0;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 2rem 0 1rem;
    border-top: 1px solid #d9d9d9;

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.1rem;
      color: #2980ba;
      text-decoration: none;
      display: flex;
      align-items: center;

      &:hover {
        text-decoration: underline;
      }
    }

    .prev-section {
      text-align: left;
      margin: 0 auto 0 0;

      .chevron {
        margin: 0 0.4rem 0 0;
      }
    }

    .next-section {
      text-align: right;

      .chevron {
        margin: 0 0 0 0.4rem;
      }
    }
  }

  li > p > img {
    margin: 1rem 0;
  }
}
