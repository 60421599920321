.breadcrumbs {
  $min-width-large: 1024px;
  $font-color-primary: #4e4e4e;
  $font-color-link: #000;
  $background-color-primary: #fff;

  @media print {
    display: none;
  }

  padding: 1.45rem 1.25rem;
  border-bottom: 1px solid #d9d9d9;
  background-color: $background-color-primary;

  color: $font-color-primary;
  text-decoration: none;

  a {
    text-decoration: none;
    font-weight: bold;
    color: $font-color-link;
  }

  .breadcrumbs-in {

    .v-el {
      margin-bottom: 1.45rem;

      &:first-child {
        @media screen and (min-width: $min-width-large) {
          margin: 0 auto 0 0;
        }
      }

      &:last-child {
        margin-bottom: 0;

        @media screen and (min-width: $min-width-large) {
          margin: 0 0 0 1.45rem;
        }
      }

      @media screen and (min-width: $min-width-large) {
        margin: 0;
      }
    }

    @media screen and (min-width: $min-width-large) {
      display: flex;
      align-items: center;
    }

    .nav-dep {
      .nav-piece {
        display: block;
        margin-bottom: 1.45rem;
        line-height: 1.45rem;

        &:last-child {
          margin-bottom: 0;
        }

        @media screen and (min-width: $min-width-large) {
          display: inline;
        }

        &.divider {
          display: none;

          @media screen and (min-width: $min-width-large) {
            display: inline;
          }
        }
      }
    }

    .action-icons {
      min-width: 120px;

      @media screen and (min-width: $min-width-large) {
        margin: 0 0 0 auto;
      }

      .action-link {
        all: unset;
        color: #000;
        cursor: pointer;
        display: inline-block;
        margin: 0 1rem 0 0;

        &:last-child {
          margin: 0;
        }

        img {
          height: 2rem;
        }
      }
    }
  }
}
